import React, {useEffect} from "react";
import classes from './Modal.module.scss';
import {Group} from "../UI/Group/Group";
import {Input} from "../UI/Input/Input";
import {Button} from "../UI/Button/Button";
import {useForm} from "../../util/hooks";
import {useDispatch, useSelector} from "react-redux";
import {useLazyQuery, useMutation} from "@apollo/client";
import {FETCH_CURRENT_REQUEST, SET_REQUEST} from "../../grapgql/gql";
import {  closeCertificateModal} from "../../store/reducers/modalReducer";

const Modal = () => {
    const dispatch = useDispatch();
    const { id, open } = useSelector(state => state.modal.certificateModal);
    const [fetch, { data: { getScRequest: request } = {}, loading }] = useLazyQuery(FETCH_CURRENT_REQUEST);

    const {onChange, onSubmit, values} = useForm(certificateHandler, {
        certificate: ''
    });

    const [setRequest, { loading: loadingSet }] = useMutation(SET_REQUEST, {
        update(_, { data: { setCodeScDevice }}) {
            dispatch(closeCertificateModal());
        }
    });


    function certificateHandler() {
        setRequest({ variables: { id, certificate: values.certificate }})
    }

    useEffect(() => {
        if (!id) return false;

        fetch({ variables: { requestID: id }});
    }, [id, open]);



    return (
        <>
            { !loading && open && (
                <div className={classes.Wrapper}>
                    <div className={classes.Container}>
                        <form className={classes.Form} onSubmit={onSubmit}>
                            <Group label="Номер сертификата">
                                <Input
                                    type="text"
                                    name="certificate"
                                    onChange={onChange}
                                    placeholder="Введите номер сертификата"
                                    value={values.certificate}
                                />
                            </Group>
                            <Group>
                                <Button>
                                    Сохранить
                                </Button>
                            </Group>
                        </form>
                    </div>
                </div>
            )}
        </>
    )
}

export default Modal;
