import React from "react";
import { saveAs } from "file-saver";
import {pdf, Document, Page, Font, StyleSheet, View, Image, Text} from "@react-pdf/renderer";
import font from "../assets/fonts/Roboto-Regular.ttf";
import logo from "../assets/img/logo-green.png";
import { compareAsc, format } from 'date-fns';
import 'moment-timezone';

Font.register({ family: 'Roboto', src: font, fontWeight: 700});



const styles = StyleSheet.create({
    page: {
        fontFamily: "Roboto",
        flexDirection: "column",
        paddingLeft: 25,
        paddingRight: 25
    },
    table: {
        paddingBottom: 30,
        fontSize: 10,
        width: 550,
        height: '100%',
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    cell: {
        flexGrow: 2,
        flexShrink: 2,
        flexBasis: "auto",
        alignSelf: "stretch"
    },
    headerText: {
        fontSize: 9,
        fontWeight: 1200,
        color: "#000"
    },
    tableText: {
        margin: 10,
        fontSize: 10
    },
    logo: {
        width: 120,
        marginLeft: 20
    },
    h1: {
        marginTop: 15,
        fontSize: 16,
        fontWeight: 2000,
        textAlign: "center"
    },
    section: {
        marginTop: 10
    },
    itemsWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    items: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        overflow: 'hidden',
        marginTop: 10
    },
    item: {
        flexDirection: 'row',
        marginBottom: 5,
        fontSize: 8
    },
    groupContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    groupItem: {
        width: '20%',
        fontSize: 9,
        flexBasis: '20%',
        padding: 5
    },
    groupContainerHeader: {
        backgroundColor: '#ebebeb',
        marginTop: 10,
        fontSize: 9
    },
    border: {
        border: 1,
        borderColor: '#000'
    },
    firstItem: {
        width: 150
    },
    date: {
        marginTop: 30
    },
    footer: {
        width: '100%'
    },
    footerItems: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 15
    },
    footerItem: {
        width: '200px',
        borderWidth: '200px',
        paddingBottom: 5,
        borderBottom: "1 solid black",
    },
    message: {
        fontSize: 8,
        marginTop: 20
    }
});


const generatePDFDocument = async (props) => {
    console.log(props);


    const blob = await pdf(
        <Document>
            <Page style={styles.page} size="A4" wrap>
                <View style={styles.table}>
                    <View style={styles.section}>
                        <View style={[styles.row, styles.header]}>
                            <View style={[styles.headerText, styles.cell]}>
                                <Image style={styles.logo} src={logo}/>
                            </View>
                            <Text style={[styles.headerText, styles.cell]}>
                                Ф 01 ДП-СМ-08-19{"\n"}
                                0500046, Республика Казахстан{"\n"}
                                г. Алматы, пр. Абая 150 нежилое помещение 1А,{"\n"}
                                Городской: +7 (727) 229 00 00 вн. 210 {"\n"}
                                Мобильный: +7 (771) 765 62 91 {"\n"}
                                E-mail: tak@geokurs.kz
                            </Text>
                        </View>
                        <Text style={styles.h1}>Акт приема передачи оборудования</Text>
                        <View style={styles.itemsWrapper}>
                            <View style={[styles.items]} >
                                <View style={[styles.item]} >
                                    <Text style={[styles.firstItem]}>Код*:</Text>
                                    <Text>{props.code}</Text>
                                </View>
                                <View style={[styles.item]} >
                                    <Text style={[styles.firstItem]}>Наименование компании:</Text>
                                    <Text>{props.companyname}</Text>
                                </View>
                                <View style={[styles.item]} >
                                    <Text style={[styles.firstItem]}>Email:</Text>
                                    <Text>{props.useremail}</Text>
                                </View>
                                <View style={[styles.item]} >
                                    <Text style={[styles.firstItem]}>Телефон:</Text>
                                    <Text>{props.userphone} ({props.username})</Text>
                                </View>
                            </View>

                            <View style={styles.date}>
                                <Text style={[styles.firstItem]}>Дата:</Text>
                                <Text>{format(new Date(props.createdAt), 'dd.MM.yyyy')}</Text>
                            </View>
                        </View>

                        <View>
                            <View style={[styles.groupContainerHeader, styles.groupContainer]}>
                                <Text style={[styles.groupItem]}>Наименование прибора</Text>
                                <Text style={[styles.groupItem]}>Серийный номер</Text>
                                <Text style={[styles.groupItem]}>Год выпуска</Text>
                                <Text style={[styles.groupItem]}>Услуга</Text>
                                <Text style={[styles.groupItem]}>Примечание</Text>
                            </View>
                            {
                                props.devices.map((item, index) => {
                                    return (
                                        <View style={[styles.groupContainer]} key={index}>
                                            <Text style={[styles.groupItem, styles.border]}>{item.model}</Text>
                                            <Text style={[styles.groupItem, styles.border]}>{item.serial}</Text>
                                            <Text style={[styles.groupItem, styles.border]}>{item.year}</Text>
                                            <Text style={[styles.groupItem, styles.border]}>{item.service}</Text>
                                            <Text style={[styles.groupItem, styles.border]}>{item.comment}</Text>
                                        </View>
                                    )
                                })
                            }
                        </View>

                        <View style={styles.message}>
                            <Text>
                                Условия сдачи в Сервисный Центр:
                            </Text>
                            <Text>
                                1.	Данный акт подтверждает прием оборудования для проведения диагностики, гарантийного или платного ремонта  и (или) поверки.
                            </Text>
                            <Text>
                                2.	В случае отказа от ремонта, клиентом оплачивается стоимость диагностики.  Акт технического состояния выдается по отдельному требованию клиента.
                            </Text>
                            <Text>
                                3.	В случае утери акта приемки, выдача оборудования производится при предъявлении документа от Предприятия-заказчика (доверенность, гарантийное письмо), для частных лиц - при наличии удостоверения личности лица сдавшего оборудование в СЦ.
                            </Text>
                            <Text>
                                4.	Заказчик обязуется оплатить счет, выставленный  за работу, в течении пяти рабочих дней  после получения уведомления и забрать отремонтированные и (или) поверенные приборы в течении следующих пяти дней. По истечении 10 дней взимается плата за хранение -  150 тенге за каждые последующие сутки (ст. 189 ГК РК). В случае отказа Клиента от оплаты за хранение, СЦ вправе удерживать оборудование до полной оплаты полагающейся суммы.
                            </Text>
                            <Text>
                                5.	В случае неявки заказчика за отремонтированным и (или) поверенным прибором после оповещения в течении трех месяцев будет расценена СЦ как отказ клиента от права собственности на имущество. В этом случае СЦ оставляет за собой право реализовать или утилизировать это оборудование по своему усмотрению в одностороннем порядке.
                            </Text>
                            <Text>
                                6.	Данный документ подписывается в двух экземплярах по одному для каждой из сторон.
                            </Text>
                            <Text>
                                7. Ориентировочный срок выполнения ремонта при наличии запчастей на складе 10 рабочих дней, срок поверки от 3 до 7 рабочих дней, по тарифу "срочность" доплата 50% от стоимости. поверки.
                            </Text>
                            <Text>
                                8. Положительные результаты поверки удостоверяются сертификатом о поверке. При отрицательном результате выписывается извещение о непригодности с указанием причины неисправности.
                            </Text>
                        </View>
                    </View>

                    <View style={styles.footer}>
                        <View style={styles.footerItems}>
                            <View style={styles.footerItem}>
                                <Text>Сдал</Text>
                            </View>
                            <View style={styles.footerItem}>
                                <Text>Принял</Text>
                            </View>
                        </View>
                        <Text>* - по коду вы можете отслеживать статус прибора на сайте geokurs.kz, в разделе "Сервисы" - "Сервисный центр".</Text>
                    </View>
                </View>
            </Page>
        </Document>
    ).toBlob();

    saveAs(blob, props.id);
};

export default generatePDFDocument;