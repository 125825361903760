import React from 'react';

const ButtonIcon = ({ color = 'red', icon, show, onClick, id }) => {
    if (!show) return false;


    return (
        <button
            data-id={id}
            className={`ui large icon ${color} button`}
            onClick={onClick}>
            <i className={`${icon} icon`} />
        </button>
    )
}

export default ButtonIcon;