import {gql, useMutation, useQuery} from '@apollo/client';
import styles from "./Export.module.scss";

const EXPORT_REQUESTS_TO_XLSX = gql`
    mutation ExportRequestsToXlsx($filter: String, $search: String) {
        exportRequestsToXlsx(filter: $filter, search: $search)
    }
`;

const ExportRequestsButton = () => {
    const [fetch, { loading, error, data }] = useMutation(EXPORT_REQUESTS_TO_XLSX, {
        variables: { filter: '', search: '' }, // Provide appropriate filter and search values
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data && data.exportRequestsToXlsx) {
                const base64String = data.exportRequestsToXlsx;

                // Convert base64 string to a Blob
                const byteCharacters = atob(base64String);
                const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                // Create a link element, use it to create a download link, and click it
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'requests.xlsx';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    });

    return (
        <div>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error.message}</p>}
            <button className={styles.Button} onClick={() => fetch()} disabled={loading}>
                Скачать выгрузку
            </button>
        </div>
    );
};

export default ExportRequestsButton;
