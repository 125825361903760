import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    certificateModal: {
        open: false,
        id: '',
        value: ''
    }
}

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openCertificateModal: (state, action) => {
            state.certificateModal.open = true;
            state.certificateModal.id = action.payload.id;
            state.certificateModal.value = action.payload.value;
        },
        closeCertificateModal: (state) => {
            state.certificateModal.open = false;
            state.certificateModal.id = '';
            state.certificateModal.value = '';
        }
    }
})

export const {
    openCertificateModal,
    closeCertificateModal
} = modalSlice.actions;

export default modalSlice.reducer;
