import React from 'react';
import ButtonIcon from "../UI/ButtonIcon/ButtonIcon";
import {useMutation} from "@apollo/client";
import { useHistory } from "react-router-dom";
import { DELETE_REQUEST } from "../../grapgql/gql";
import generatePDFDocument from "../../helpers/generatePDFDocument";

const Controls = ({
    item,
    link,
    controls = {}
}) => {
    let history = useHistory();
    const [deleteRequest, { loading }] = useMutation(DELETE_REQUEST);


    return (
        <>
            <ButtonIcon
                color="green"
                icon="info circle"
                show={controls.info}
                onClick={() => history.push(`${link}${item.id}`)}
            />
            <ButtonIcon
                color="orange"
                icon="print"
                show={controls.pdf}
                onClick={() => generatePDFDocument(item)}
            />
            <ButtonIcon
                color="red"
                icon="trash"
                id={item.id}
                show={controls.delete}
                onClick={() => {
                    deleteRequest({
                        variables: {
                            requestID: item.id
                        }
                    })
                }}
            />

        </>
    )
}

export default Controls;