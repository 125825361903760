import React, { useContext } from 'react';
import Loader from "react-loader-spinner";
import Paginator from "../Paginator/Paginator";
import classes from './Page.module.scss';
import { AuthContext } from "../../context/auth";
import { Navbar } from "../Navbar/Navbar";
import { motion } from "framer-motion";

const Page = ({ children, title, loading, controls, paginator, fetchMore, position, callback }) => {
    const { user } = useContext(AuthContext);

    return (
        <>
            <div className={classes.Wrapper}>
                <div className={classes.Title}>
                    { title }
                </div>
                { (controls || paginator) &&
                    <div className={[classes.Controls, position === 'end' && classes.ControlsEnd].join(' ')}>
                        {controls && controls}
                        {paginator && fetchMore &&
                            <Paginator
                                fetchMore={fetchMore}
                                paginator={paginator}
                                setPage={callback}
                            />
                        }
                    </div>
                }
                <div className={classes.Content}>
                    { loading &&
                        <Loader
                            className="loading"
                            type="Circles"
                            color="#284255"
                            height={50}
                            width={50}
                            timeout={3000}
                        />
                    }

                    { !loading &&
                        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                            { children }
                        </motion.div>
                    }
                </div>
            </div>
            { user && <Navbar /> }
        </>
    )
}

export default Page;