import React from 'react';
import store from "./store";
import AuthRoute from './hoc/AuthRouter';
import { Provider } from "react-redux";
import {AuthProvider} from './context/auth';
import PrivateRouter from './hoc/PrivateRouter';
import {BrowserRouter as Router} from 'react-router-dom';

import Login from "./pages/Login/Login";
import Create from "./pages/Create/Create";
import Request from "./pages/Request/Request";
import Company from "./pages/Company/Company";
import Status from "./pages/Status/Status";
import Archive from "./pages/Archive/Archive";
import Sklad from "./pages/Sklad/Sklad";
import Current from "./pages/Current/Current";
import CompanyCurrent from "./pages/CompanyCurrent/CompanyCurrent";


import { SearchProvider } from "./context/search";
import 'semantic-ui-css/semantic.min.css'
import './App.css';


function App() {
    return (
        <Provider store={store}>
        <AuthProvider>
            <SearchProvider>
                <Router>
                    <AuthRoute path="/" exact component={Login} />
                    <PrivateRouter exact path="/create" component={Create} />
                    <PrivateRouter exact path="/request" component={Request} />
                    <PrivateRouter exact path="/company" component={Company} />
                    <PrivateRouter exact path="/company/:id" component={CompanyCurrent} />
                    <PrivateRouter exact path="/status" component={Status} />
                    <PrivateRouter exact path="/archive" component={Archive} />
                    <PrivateRouter exact path="/sklad" component={Sklad} />
                    <PrivateRouter exact path="/request/:id" component={Current} />
                </Router>
            </SearchProvider>
        </AuthProvider>
        </Provider>
    );
}

export default App;
