import React, {useState, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import classes from './Login.module.scss';
import { Group } from "../../components/UI/Group/Group";
import { Input } from '../../components/UI/Input/Input';
import { Button } from '../../components/UI/Button/Button';
import { AuthContext } from '../../context/auth';
import { useForm } from '../../util/hooks';
import { useMutation } from '@apollo/client';
import {LOGIN_USER} from '../../grapgql/gql';

const Login = () => {
    const context = useContext(AuthContext);

    const [errors, setErrors] = useState({});
    let history = useHistory();


    const {onChange, onSubmit, values} = useForm(loginUserHandler, {
        login: '',
        password: ''
    });

    const [loginUser, { loading }] = useMutation(LOGIN_USER, {
        update(_, { data: { login: userData }}) {
            context.login(userData);
            history.push('/create');
        },
        onError(err) {
            setErrors(err.graphQLErrors[0].extensions.exception.errors);
        },
        variables: values
    });

    function loginUserHandler() {
        loginUser();
    }

    return (
        <div className={classes.Wrapper}>
            <form className={classes.Form} onSubmit={onSubmit}>
                <h2>Авторизация</h2>
                <p>Для авторизации введите данные</p>

                <Group label="Введите логин" error={errors.login}>
                    <Input
                        type="text"
                        placeholder="Введите логин"
                        name="login"
                        value={values.login}
                        onChange={onChange}
                    />
                </Group>
                <Group label="Введите пароль" error={errors.password}>
                    <Input
                        type="password"
                        placeholder="Введите пароль"
                        name="password"
                        value={values.password}
                        onChange={onChange}
                    />
                </Group>

                <Group>
                    <Button>
                        Войти
                    </Button>
                </Group>
            </form>
        </div>
    )
};

export default Login;
