import React from 'react';
import Page from "../../components/Page/Page";
import { useQuery } from "@apollo/client";
import { FETCH_STATUS } from '../../grapgql/gql';
import { Table, Td, Tr } from "../../components/UI/Table/Table";


const Status = () => {
    const { loading, data: { getScStatus: status } = {} } = useQuery(FETCH_STATUS);

    return (
        <Page
            title="Статусы заявок"
            loading={loading}
        >
            <Table value={[
                {name: "number", label: '№'},
                {name: "name", label: 'Название статуса'},
                {name: "value", label: 'Значение статуса' }
            ]}>
                { status && status.map((item, index) => {
                    return (
                        <Tr key={item.id}>
                            <Td data-label="№">{index + 1}</Td>
                            <Td data-label="Название статуса">{item.label}</Td>
                            <Td data-label="Значение статуса">{item.value}</Td>
                        </Tr>
                    )
                }) }
            </Table>
        </Page>
    )
}

export default Status;

