import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import Page from '../../components/Page/Page';
import {Group} from "../../components/UI/Group/Group";
import {Input} from "../../components/UI/Input/Input";
import {Grid} from "../../components/Grid/Grid";
import {useQuery, useLazyQuery, useMutation} from "@apollo/client";
import CreatableSelect from 'react-select/creatable';
import {Device} from "../../components/Device/Device";
import classes from "./Create.module.scss";
import generatePDFDocument from "../../helpers/generatePDFDocument";
import {FETCH_COMPANY, GET_COMPANY, REGISTER_REQUEST, FETCH_REQUEST} from "../../grapgql/gql";
import {Loader} from "semantic-ui-react";


const Create = () => {
    const deviceInputs =  {
        model: {
            label: 'Наименование прибора',
            type: "create"
        },
        serial: {
            label: 'Серийный номер',
            type: "text"
        },
        year: {
            label: 'Года выпуска',
            type: "text"
        },
        services: {
            label: 'Наименование услуги',
            type: "select"
        },
        comment: {
            label: 'Комментарий/Примечание',
            type: "textarea"
        }
    };
    const deviceInput = {
        model: '',
        serial: '',
        year: '',
        service: '',
        comment: ''
    }
    const initialState = {
        companyname: '',
        username: '',
        userphone: '',
        useremail: '',
        devices: [
            deviceInput
        ]
    }

    const history = useHistory();
    const [errors, setErrors] = useState({});
    const [companyItem, setCompanyItem] = useState();
    const [device, setDevice] = useState([
        deviceInputs
    ]);

    const [values, setValues] = useState(initialState);


    const onChange = (event) => {
        const {name, value, dataset: { idx } = {}} = event.target;
        if (!idx) {
            setValues({...values, [name]: value});
        }
        if (idx) {
            const updatedDevice = [ ...values.devices ];

            updatedDevice[idx][name] = value;

            console.log(idx, name, value);
            setValues({ ...values, devices: updatedDevice });
        }

    }


    const {companyLoading, data: {getScCompany: company} = {}} = useQuery(FETCH_COMPANY, {
        variables: {
            page: 1,
            limit: 10000
        }
    })


    const [getCompany, {loadingCompany, data: {getScCompanyInfo: companyInfo} = {}}] = useLazyQuery(GET_COMPANY, {
        variables: {companyId: companyItem},
        onCompleted: () => {
            const {companyname, username, userphone, useremail} = companyInfo;
            setValues({...values, companyname, username, useremail, userphone});
        }
    });


    const [addDevice, {loading}] = useMutation(REGISTER_REQUEST, {
        update(proxy, {data: {createScRequest: create}}) {
            generatePDFDocument(create).then(() => {
                history.push('/request')
            });
        },
        variables: values
    });

    const onSubmit = (event) => {
        event.preventDefault();
        addDevice();
    }


    const companyHandler = (event) => {
        const {id} = event;

        if (id) {
            setCompanyItem(id);
            getCompany();
        }
    }

    const deviceHandler = (event) => {
        const {id} = event;

        if (id) {
            setCompanyItem(id);
            getCompany();
        }
    }

    const addHandler = (event) => {
        event.preventDefault();

        const deviceValue = [ ...values.devices ];
        deviceValue.push(deviceInput);

        setValues({ ...values, devices: deviceValue });
        setDevice([ ...device, deviceInputs ]);
    }

    return (
        <Page title="Создание заявки">
            <form onSubmit={onSubmit}>
                { loading &&
                    <Loader
                        className="loading"
                        type="Circles"
                        color="#284255"
                        height={50}
                        width={50}
                        timeout={3000}
                    />
                }
                <h3>Контактные данные</h3>
                <hr/>
                <Grid>
                    <Group label="Наименование компании" error={errors.companyname}>
                        <CreatableSelect
                            isClearable
                            classNamePrefix="select"
                            isLoading={companyLoading}
                            placeholder="Выберите или добавьте..."
                            options={company && company.data.map((item) => {
                                return {
                                    value: item.companyname,
                                    label: item.companyname,
                                    name: 'companyname',
                                    id: item.id
                                }
                            })}
                            onInputChange={event => event && onChange({ target: {value: event, name: 'companyname'}})}
                            onChange={event => companyHandler(event)}
                        />
                    </Group>
                    <Group label="Контактное лицо" error={errors.username}>
                        <Input
                            type="text"
                            name="username"
                            value={values.username}
                            onChange={onChange}
                        />
                    </Group>
                    <Group label="Телефон" error={errors.userphone}>
                        <Input
                            type="text"
                            name="userphone"
                            value={values.userphone}
                            onChange={onChange}
                        />
                    </Group>
                    <Group label="Email" error={errors.useremail}>
                        <Input
                            type="text"
                            name="useremail"
                            value={values.useremail}
                            onChange={onChange}
                        />
                    </Group>
                </Grid>

                <h3>Информация о приборе</h3>
                <hr/>
                <Grid type="dark">
                    { device.map((devices, index) => {
                       return (
                           <Device
                               key={index}
                               devices={devices}
                               device={device}
                               index={index}
                               values={values}
                               onChange={onChange}
                               deviceHandler={deviceHandler}
                           />
                       )
                    })}
                </Grid>

                <button onClick={addHandler} className={[classes.Button, classes.Green].join(' ')}>Добавить устройство</button>
                <button className={[classes.Button, classes.Orange].join(' ')} disabled={loading}>Зарегистрировать</button>
            </form>
        </Page>
    )
};


export default Create;
