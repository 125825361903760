import { gql } from '@apollo/client';

export const LOGIN_USER = gql`
    mutation login(
        $login: String!
        $password: String!
    ) {
        login(login: $login, password: $password) {
            id
            email
            name
            lastname
            createdAt
            token
        }
    }
`;


export const FETCH_COMPANY = gql`
    query Company (
        $page: Int
        $limit: Int
        $search: String
    ) {
        getScCompany(
            page: $page,
            limit: $limit,
            search: $search
        ) {
            data {
                id
                companyname
                useremail
                username
                userphone
                createdAt
            }
            paginator {
                itemCount
                perPage
                pageCount
                currentPage
                slNo
                hasPrevPage
                hasNextPage
                prev
                next
            }
        }
    }
`;

export const GET_COMPANY = gql`
    query Company($companyId: String!) {
        getScCompanyInfo(companyId: $companyId) {
            id
            companyname
            useremail
            username
            userphone
            createdAt
        }
    }
`;

export const GET_CURRENT_COMPANY = gql`
    query Company($companyId: String!) {
        getScCompanyInfo(companyId: $companyId) {
            id
            companyname
            useremail
            username
            userphone
            createdAt
            requests {
                id
                code
                companyname
                username
                userphone
                useremail
                status {
                    label
                    value
                    type
                }
                createdAt
                devices {
                    model
                    serial
                    year
                    service
                }
            }
        }
    }
`;

export const REGISTER_REQUEST = gql`
    mutation createScRequest(
        $companyname: String!
        $username: String!
        $userphone: String!
        $useremail: String!
        $devices: [RequestInputDevices]!
    ) {
        createScRequest(
            requestInput: {
                companyname: $companyname
                username: $username
                userphone: $userphone
                useremail: $useremail
                devices: $devices
            }
        ) {
            id
            code
            companyname
            username
            useremail
            userphone
            createdAt
            devices {
                model
                serial
                year
                service
                comment
            }
        }
    }
`;

export const FETCH_REQUEST_SKLAD = gql`
    query Request (
        $page: Int
        $limit: Int
        $search: String
    ) {
        getScRequests(
            filter: "SKLAD"
            page: $page,
            limit: $limit,
            search: $search
        ) {
            data {
                id
                code
                userphone
                username
                useremail
                companyname
                createdAt
                devices {
                    model
                    comment
                    year
                    serial
                    service
                }
                status {
                    label
                    value
                    type
                }
            }
            paginator {
                itemCount
                perPage
                pageCount
                currentPage
                slNo
                hasPrevPage
                hasNextPage
                prev
                next
            }
        }
    }
`;

export const FETCH_REQUEST = gql`
    query Request (
        $page: Int
        $limit: Int
        $search: String
    ) {
        getScRequests(
            filter: "INWORK"
            page: $page,
            limit: $limit,
            search: $search
            
        ) {
            data {
                id
                code
                userphone
                username
                useremail
                companyname
                createdAt
                status {
                    label
                    value
                    type
                }
                devices {
                    model
                    comment
                    year
                    serial
                    service
                }
            }
            paginator {
                itemCount
                perPage
                pageCount
                currentPage
                slNo
                hasPrevPage
                hasNextPage
                prev
                next
            }
        }
    }
`;

export const FETCH_CURRENT_REQUEST = gql`
    query Request (
        $requestID: String!
    ) {
        getScRequest(
            requestID: $requestID
        ) {
            id
            code
            userphone
            username
            useremail
            companyname
            createdAt
            status {
                label
                value
                type
            }
            devices {
                model
                comment
                year
                serial
                service
                certificate
            }
        }
    }
`;

export const DELETE_REQUEST = gql`
    mutation deleteScRequst(
        $requestID: String!
    ) {
        deleteScRequest(requestID: $requestID)
    }
`;


export const FETCH_STATUS = gql`
    {
        getScStatus {
            id
            label
            value
            type
        }
    }
`;


export const CHANGE_STATUS = gql`
    mutation changeScRequest(
        $id: String!
        $label: String!
        $value: String!
        $type: String!
    ) {
        changeScRequest(id: $id, label: $label, value: $value, type: $type)
    }
`;


export const FETCH_ARCHIVE = gql`
    query Request (
        $page: Int
        $limit: Int
        $search: String
    ) {
        getScRequests(
            filter: "DONE"
            page: $page,
            limit: $limit,
            search: $search
        ) {
            data {
                id
                code
                userphone
                username
                useremail
                companyname
                createdAt
                finishedAt
                status {
                    label
                    value
                    type
                }
                devices {
                    model
                    comment
                    year
                    serial
                    service
                }
            }
            paginator {
                itemCount
                perPage
                pageCount
                currentPage
                slNo
                hasPrevPage
                hasNextPage
                prev
                next
            }
        }
    }
`;

export const SET_REQUEST = gql`
    mutation setCodeScDevice(
        $id: ID!
        $certificate: String!
    ) {
        setCodeScDevice(id: $id, certificate: $certificate)
    }
`;
