import React from 'react';
import App from './App';
import { ApolloClient, InMemoryCache, createHttpLink, ApolloProvider,  } from '@apollo/client';
import { setContext } from "apollo-link-context";


const httpLink = createHttpLink({
    uri: process.env.NODE_ENV === 'production' ? 'https://api.geokurs.kz/api' : 'http://localhost:5001/api'
});

const authLink = setContext(() => {
    const token = localStorage.getItem('jwtToken');
    return {
        headers: {
            Authorization: token ? `Bearer ${token}` : ''
        },
    }
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: "cache-and-network",
        }
    }
});



export default (
    <ApolloProvider client={client}>
        <App />
    </ApolloProvider>
)


