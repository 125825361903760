import React from 'react';
import classes from './Button.module.scss';


export const Button = ({ children }) => {
    return (
        <button className={classes.Button}>
            {children}
        </button>
    )
}